import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Location} from '@angular/common';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {InvoiceService} from '../../services/invoice.service';

@Injectable({
  providedIn: 'root'
})
export class CanClaimTaskGuard implements CanActivate {
  constructor(
    private location: Location,
    private router: Router,
    private taskService: InvoiceService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const comingFromTaskDetails = this.location.path().endsWith('/tasks');

    return this.taskService
      .claimAndGetTaskFromApi(route.params.id)
      .pipe(
        catchError((error, e) => {
          switch (error.status) {
            case 400:
            case 403:
            case 404:
              if (comingFromTaskDetails) {
                this.taskService.handleError(error);
              } else {
                // TODO: tackle back boutton from list to claim ;)
                this.router.navigate(['/notfound']);
              }
              break;
            default:
              break;
          }
          return throwError('An error has occurred. Try later');
        })
      )
      .pipe(
        map(formMetadata => {
          route.data = {formMetadata};
          return true;
        })
      );
  }
}
