<div class="wrapper">
  <div class="overlay">
    <div *ngIf="showLoading" class="spinner-wrapper-too">
      <mat-spinner
        [color]="spinnerOptions.color"
        [mode]="spinnerOptions.mode"
        [value]="spinnerOptions.value"
      ></mat-spinner>
    </div>
  </div>
</div>
