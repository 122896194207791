<div #viewer class="capture-document-canvas">
  <canvas id="bgCanvas" #bgCanvas style="left: 0;top: 0; position: absolute;"></canvas>
  <canvas id="canvas" #canvas style="left: 0; top: 0;position: absolute" (mouseup)="onMouseup($event)"
          (mousedown)="onMousedown($event)"
          (mousemove)="onMousemove($event)"
          (mouseover)="onMouseover($event)"
          (mouseleave)="onMouseleave($event)"
  >
  </canvas>
  <p id="tooltip" class="tooltipBox" [ngStyle]="tooltipStyle"
     *ngIf="captureService.focusedInput$ | async as message">{{message}}</p>
</div>
