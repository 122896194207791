import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';

import {KeycloakTokenParsed} from '../../assets/keycloak-js/keycloak';
import {KeycloakService} from '../core/guards/keycloak.service';
import {IAuthUser} from '../model/token-user.model';
import _ from 'lodash';


const helper = new JwtHelperService();


@Injectable({providedIn: 'root'})
export class AuthenticationService {
  constructor(private http: HttpClient, public keycloakService: KeycloakService) {
  }

  logout() {
    this.keycloakService.logout();
  }

  isLoggedIn() {
    return this.keycloakService.isLoggedIn();
  }

  getUserToken() {
    const accessToken = this.keycloakService.getToken();
    return accessToken;
  }

  getUserAuthData(): IAuthUser {
    const accessToken = this.keycloakService.keycloak.tokenParsed;

    const profile = this.keycloakService.keycloak.profile;
    const idTokenParsed = this.keycloakService.keycloak.idTokenParsed;

    return this.toAuthUser(accessToken, idTokenParsed);
  }

  private toAuthUser(accessToken: KeycloakTokenParsed, idToken: KeycloakTokenParsed) {
    const authUSer: IAuthUser = {authorities: [], client_id: '', exp: 0, jti: '', scope: [], userInfo: undefined, user_name: ''};
    authUSer.user_name = accessToken.name;

    authUSer.userInfo = {
      user: {
        id: idToken.email,
        firstName: idToken.given_name,
        lastName: idToken.family_name
      },
      groups: _.map(accessToken.realm_access.roles, (role: string) => ({
        id: role,
        name: role
      }))
    };

    return authUSer;
  }

  updateToken(minValidity: number) {
    return this.keycloakService.keycloak.updateToken(minValidity);
  }
}
