import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {IAuthUser} from '../model/token-user.model';

@Component({
  selector: 'app-capture-app',
  templateUrl: './capture-app.component.html',
  styleUrls: ['./capture-app.component.scss']
})
export class CaptureAppComponent implements OnInit {

  public authUser: IAuthUser;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  logout() {
    this.authenticationService.logout();
  }


  ngOnInit(): void {
    this.authUser = this.authenticationService.getUserAuthData();
  }

  public navigateToListTasks(): void {
    this.router.navigate(['/capture']).then();
  }
}
