import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CaptureListComponent} from './capture-list/capture-list.component';
import {CaptureComponent} from './capture/capture.component';
import {CanClaimTaskGuard} from './core/guards/can-activate.guard';
import {CaptureAppComponent} from './capture-app/capture-app.component';
import {AuthGuard} from './core/guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'capture',
    pathMatch: 'full'
  },
  { path: 'capture',
    component: CaptureAppComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: CaptureListComponent, canActivate: [AuthGuard]},
      { path: ':id',
        component: CaptureComponent,
        canActivate: [AuthGuard, CanClaimTaskGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
