import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CaptureComponent} from './capture/capture.component';
import {CaptureViewerComponent} from './capture/capture-viewer/capture-viewer.component';
import {CaptureFormComponent} from './capture/capture-form/capture-form.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CaptureListComponent} from './capture-list/capture-list.component';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatSortModule} from '@angular/material/sort';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatDividerModule} from '@angular/material/divider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {CaptureAppComponent} from './capture-app/capture-app.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {SpinnerComponent} from './spinner/spinner.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LoaderInterceptor} from './core/interceptors/loader.interceptor';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {DateInFutureDirective} from './utils/date-in-future.directive';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatBadgeModule} from '@angular/material/badge';
import {KeycloakService} from './core/guards/keycloak.service';
import {JwtInterceptor} from './core/guards/jwt.interceptor';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    CaptureComponent,
    CaptureViewerComponent,
    CaptureFormComponent,
    CaptureListComponent,
    CaptureAppComponent,
    SpinnerComponent,
    DateInFutureDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatGridListModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    FormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    HttpClientModule,
    MatSortModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    MatListModule,
    PdfViewerModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatButtonToggleModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    MatSelectModule,
    MatCheckboxModule,
    MatBadgeModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'fr'},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

function initializeKeycloak(keycloakService: KeycloakService) {
  return () => keycloakService.keycloak.init({
    onLoad: 'login-required',
    responseMode: 'query',
    messageReceiveTimeout: 100000,
    enableLogging: true
  });
}

