import {MatDateFormats} from '@angular/material/core';
import {Invoice} from '../model/invoice';
import * as moment from 'moment';
import {normalizeAmounts} from './cleaners';

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

export function adaptToAPI(formData: Invoice): any {
  const obj: any = {};

  obj.invoice_recipientParty_name = formData.recipient_party.company_name;
  obj.invoice_recipientParty_legalId = formData.recipient_party.company_legal_id;
  obj.invoice_invoiceNumber = formData.invoice_number;
  obj.invoice_orderReference = formData.orderReference;
  obj.invoice_issueDate = formatDate(formData.issue_date);

  obj.invoice_taxExclusiveAmount = normalizeAmounts(formData.total_amount);
  obj.invoice_taxAmount = normalizeAmounts(formData.tax_total);
  obj.invoice_payableAmount = normalizeAmounts(formData.legal_monetary_total);

  obj.invoice_payeeFinancialAccount = formData.payeeFinancialAccount;
  obj.invoice_senderParty_name = formData.sender_party.company_name;
  obj.invoice_senderParty_legalId = formData.sender_party.company_legal_id;
  obj.invoice_senderParty_taxId = formData.sender_party.company_tax_id;
  obj.invoice_documentCurrencyCode = formData.documentCurrencyCode;
  return obj;
}

// TODO: refactor to common js library
// Code copied from: konta-webui-ng/src/app/shared/formHepler.ts
function formatDate(date: any): string {
  const API_DATE_FORMAT = 'YYYY-M-D';

  if (date !== undefined && date !== null) {
    return moment(date).format(API_DATE_FORMAT);
  } else {
    return null;
  }
}
