<mat-grid-list cols="4" rowHeight="fit" [style]="heightStyle">
  <mat-grid-tile class="document-viewer" colspan="3">
    <app-capture-viewer *ngIf="isCanvas === true" (heightChanged)="heightChanged($event)"
                        class="capture-document-canvas"></app-capture-viewer>
    <div *ngIf="isCanvas === false" #documentViewer class="document-viewer">
      <pdf-viewer
        *ngIf="pdfsrc !== undefined; else imageViewer"
        [src]="pdfsrc"
        [autoresize]="true"
        [original-size]="false"
        [render-text]="true">
      </pdf-viewer>

      <ng-template #imageViewer>
        <canvas #canvas></canvas>
      </ng-template>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <app-capture-form class="capture-document-form"></app-capture-form>
  </mat-grid-tile>
</mat-grid-list>
