import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  // tslint:disable-next-line:variable-name
  public _loader: Subject<boolean> = new Subject();
  public loader$: Observable<boolean> = this._loader.asObservable();

  constructor(private router$: Router) {}

  /**
   * Start the app loading state
   */
  public startLoading(): void {
    const param = this.router$.url.split('/').pop();

    if (this.router$.isActive(`/workspaces/${param}`, true)) {
      this._loader.next(false);
    } else {
      this._loader.next(true);
    }
  }

  /**
   * Stop the app loading state
   */
  public stopLoading(): void {
    this._loader.next(false);
  }
}
