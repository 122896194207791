import {Party} from './party';
import {parse} from 'date-fns';

/*interface IField {
  fieldType?: string;
  id?: string;
  name?: string;
  type?: string;
  value?: any;
  required?: boolean;
  readonly?: boolean;
  overrideId?: boolean;
  placeholder?: string | null;
  layout?: string | null;
}

interface IOutcome {
  id: string;
  name: string;
}

export interface IFormMetadata {
  id: string;
  name: string;
  description: string;
  key: string;
  version: number;
  fields: IField[];
  outcomes: IOutcome[];
  outcomeVariableName: any;
}*/

export class Invoice {
  constructor(
    public invoiceId?:string,
    public recipient_party?: Party,
    public invoice_number?: any,
    public orderReference?: any,
    public issue_date?: any,
    public total_amount?: any,
    public tax_total?: any,
    public legal_monetary_total?: any,
    public payeeFinancialAccount?: any,
    public sender_party?: Party,
    public workspace_id?: string,
    public documentCurrencyCode?: string
  ) {
  }

  setFieldAtIndex(index: number, result: any) {
    switch (index) {
      case 0: {
        this.recipient_party.company_name = result;
        break;
      }
      case 1: {
        this.recipient_party.company_legal_id = result;
        break;
      }
      case 2: {
        this.invoice_number = result;
        break;
      }
      case 3: {
        this.orderReference = result;
        break;
      }
      case 4: {
        this.issue_date = result;
        break;
      }
      case 5: {
        this.total_amount = result;
        break;
      }
      case 6: {
        this.tax_total = result;
        break;
      }
      case 7: {
        this.legal_monetary_total = result;
        break;
      }
      case 8: {
        this.sender_party.company_name = result;
        break;
      }
      case 9: {
        this.sender_party.company_legal_id = result;
        break;
      }
      case 10: {
        this.payeeFinancialAccount = result;
        break;
      }
      case 11: {
        this.documentCurrencyCode = result;
        break;
      }
    }
  }

  /** When retrieving invoiceForm metadata from API set each field with brain corresponding predictions value */
  setFieldWithBrainPrediction(processVariables: any) {
    this.recipient_party.company_name = processVariables.invoice_recipientParty_name || null;
    this.recipient_party.company_legal_id = processVariables.invoice_recipientParty_legalId || null;
    this.invoice_number = processVariables.invoice_invoiceNumber || null;
    this.orderReference = processVariables.invoice_orderReference || null;
    const issueDate = processVariables.invoice_issueDate;
    if (Array.isArray(issueDate) && issueDate.length > 2) {
      const y = issueDate[0];
      const m = issueDate[1];
      const d = issueDate[2];
      const issueDateStr = y + '-' + m + '-' + d;
      this.issue_date = parse(issueDateStr, 'yyyy-MM-dd', new Date());
    }

    this.total_amount = processVariables.invoice_taxExclusiveAmount || null;
    this.tax_total = processVariables.invoice_taxAmount || null;
    this.legal_monetary_total = processVariables.invoice_payableAmount || null;
    this.sender_party.company_name = processVariables.invoice_senderParty_name || null;
    this.sender_party.company_legal_id = processVariables.invoice_senderParty_legalId || null;
    this.sender_party.company_tax_id = processVariables.invoice_senderParty_taxId || null;
    this.workspace_id = processVariables.workspace_id;
    this.documentCurrencyCode = processVariables.invoice_documentCurrencyCode;
  }
}
