<mat-toolbar class="mat-elevation-z4" color="primary">
  <button class="back-button" mat-icon-button color="primary" (click)="navigateToListTasks()">
    <mat-icon class="back-icon">arrow_back_ios</mat-icon>
  </button>
  <span class="navbar-spacer"></span>
  <span>
    <p>
      {{ authUser.userInfo.user.firstName }}
      {{ authUser.userInfo.user.lastName }}
    </p>
  </span>
  <button mat-icon-button (click)="logout()">
    <mat-icon
      aria-label="Example user verified icon"
      matTooltip="Se déconnecter">
      power_settings_new
    </mat-icon>
  </button>
</mat-toolbar>
<router-outlet></router-outlet>
