import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Workspace} from '../model/workspaces.model';

@Injectable({providedIn: 'root'})
export class WorkspacesService {
  readonly WORKSPACES_URL = environment.backendUrl + '/invoicestore/dataops/api/workspaces';

  constructor(private http: HttpClient) {
  }

  getWorkspace(workspaceId: string): Observable<any> {
    return this.http.get<Workspace>(`${this.WORKSPACES_URL}/${workspaceId}`);
  }

}
