import {Injectable} from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {AttachmentDetails} from '../model/attachment-details';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  readonly CAPTURE_URL = environment.backendUrl + '/capture/invoices';
  readonly DATA_OPS_URL = environment.backendUrl + '/invoicestore/dataops/api';
  readonly BRAIN_URL = environment.backendUrl + '/brain-monitor/api';
  readonly OCR_URL = environment.backendUrl + '/invoicestore/dataops/api/ocr-annotations';
  readonly tasksURL = environment.backendUrl + '/kontaflow/task/app/capture/query/tasks';
  readonly claimURL = environment.backendUrl + '/kontaflow/task/app/rest/tasks';
  readonly FORM_URL = environment.backendUrl + '/kontaflow/task/app/capture/task-forms';
  readonly attachmentURL = environment.backendUrl + '/invoicestore/app/api/attached-documents';
  taskErrors: Subject<ErrorMessage> = new Subject<ErrorMessage>();


  private processDefKey = environment.captureProcessDefKey;

  private taskDefinitionKey = 'capture';

  constructor(private http: HttpClient) {
  }

  getTasks(pageIndex: number,
           pageSize: number): Observable<any> {
    const httpParams = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());
    return this.http.get<any>(this.CAPTURE_URL, {params: httpParams});
  }

  getTaskListFromAPI(workspaceId: string, tenantIdentifier: string, sortDirection: 'asc' | 'desc', pageIndex: number, pageSize: number): Observable<any> {

    return this.http.post<any>(this.tasksURL,
      {
        state: 'open',
        assignment: 'myTasks',
        taskDefinitionKey: this.taskDefinitionKey,
        processDefinitionKey: this.processDefKey,
        includeCompleteProcessInstance: true,
        workspaceId,
        tenantIdentifier,
        page: pageIndex,
        size: pageSize,
        sort: sortDirection === 'asc' ? 'created-asc' : 'created-desc'
      }
    );
  }

  claimAndGetTaskFromApi(taskId: string) {
    return this.http.put(`${this.claimURL}/${taskId}/action/claimAndGet`, {
      observe: 'response'
    });
  }

  getOcrInformationByInvoiceId(invoiceId: string): Observable<any> {
    const url = `${this.OCR_URL}/${invoiceId}`;
    return this.http.get<any>(url);
  }

  getTaskByIdFromAPI(taskId): Observable<any> {
    return this.http.get<any>(`${this.claimURL}/${taskId}`);
  }

  unclaimTaskFromAPI(taskId: string): Observable<any> {
    return this.http.put(`${this.claimURL}/${taskId}/action/unclaim`, {
      observe: 'response'
    });
  }

  getSupplierCount(legalId: string): Observable<any> {
    const params = new HttpParams()
      .set('iceSupplier.equals', legalId);
    return this.http.get<any>(`${this.BRAIN_URL}/templates/count`, {params});
  }

  public downloadFile(invoiceId: string, attachmentType: string): Observable<HttpEvent<Blob>> {
    const myHeadears = new HttpHeaders();
    myHeadears.set('Content-Type', 'application/json');
    return this.http.request(new HttpRequest(
      'POST',
      `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`,
      {attachmentType},
      {
        headers: myHeadears,
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      switch (error.status) {
        case 400:
          this.taskErrors.next({
            status: error.status,
            message: error.error.message,
            key: error.error.messageKey
          });
          break;
        case 404:
          this.taskErrors.next({
            status: error.status,
            message: error.error.message,
            key: error.error.messageKey
          });
          break;
        default:
          break;
      }
    }

    return throwError('An error has occurred. Try later');
  }

  submitInvoiceForm(
    tenantIdentifier: string,
    invoiceId: string,
    taskId: string,
    values: any,
    formId: string,
    outcome: string): Observable<any> {
    return this.http.post<any>(`${this.FORM_URL}/${taskId}`, {
      tenantIdentifier,   invoiceId,
      values,
      formId,
      outcome
    });
  }

  getAttachmentDetailsFromAPI(
    invoiceId: string,
    documentType: string
  ): Observable<AttachmentDetails[]> {
    const httpParams = new HttpParams()
      .set('invoiceId.equals', invoiceId)
      .set('documentType.equals', documentType);

    return this.http.get<AttachmentDetails[]>(this.attachmentURL, {
      params: httpParams
    });
  }
}

interface ErrorMessage {
  status: number;
  message: string;
  key: string;
}
